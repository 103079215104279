.loading {

    animation-name: rotation;

    animation-duration: 1.3s;

    animation-iteration-count: infinite;

    animation-timing-function: linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}